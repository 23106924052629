.abc {
  transition: .3s;
}

.abc:hover {
  background-color:#40a9ff1c;
}

.customCopyInput .ant-typography-copy {
  float: right;
}